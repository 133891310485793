
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, AvatarBadge, Box, Button, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { MdCalendarMonth, MdCheck, MdClose, MdLocationCity } from 'react-icons/md';
import { useCompassQuery } from '@/hooks/query';
import { getMission, getMissionKey } from '@/api/mission';
import Loader from '@/components/Loader/Loader';
import { TbCheck, TbClock, TbHourglass, TbMap, TbQuestionMark } from 'react-icons/tb';
import { poppinsFont } from '@/utils/string';
import { PegassLogo } from '@/components/PegassLogo/PegassLogo';
import { parseISO } from 'date-fns';
import { formatDateShort } from '@/utils/datesFormat';


interface MissionDetailProps {
  missionId: number | undefined,
  onClose: () => void
}

export const MissionDetail = ({ missionId, onClose }: MissionDetailProps) => {

  const { isLoading, data, isSuccess } = useCompassQuery([getMissionKey, missionId], () => getMission(missionId || ''))

  const mission = data?.data?.attributes
  const structure = data?.data?.meta?.structure
  return (
    <Box position={'sticky'} maxH={'100vh'} overflowY={'auto'} top={'0px'} borderLeft={'1px solid'} borderLeftColor={'bg.muted'} height={'100%'}>
      <HStack alignItems={'center'} px={6} py={3} alignContent={'flex-start'} justifyContent={'space-between'} borderBottom={'1px solid'} borderBottomColor={'bg.muted'}>
        <Heading size={'xs'}>{data?.data?.attributes?.name}</Heading>
        <Button variant={'ghost'} onClick={onClose}><MdClose /></Button>
      </HStack>
      {!isSuccess && <Loader />}
      {isSuccess && mission && (<Box p={6} >
        <VStack flexWrap={'wrap'} alignItems={'flex-start'} spacing={6} alignContent={'flex-start'} justifyContent={'flex-start'}>
          <Button w={'100%'} leftIcon={<PegassLogo h={5} />} variant={'primary-on-accent'} colorScheme='red' fontSize={'lg'} fontFamily={poppinsFont} fontWeight={'bold'} target='_blank' rel='noopener' as={RouterLink} to={`https://pegass.croix-rouge.fr/planning-des-activites/activite/${mission.foreign_id}/`}>Inscription</Button>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack spacing={1}>
              <Text color='fg.subtle'><MdCalendarMonth /></Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                Horaires
              </Text>
            </HStack>
            <Text noOfLines={1} textTransform={'capitalize'}>{formatDateShort(parseISO(mission.begin_at), {}, 'EEEE d LLL HH:mm')} - {formatDateShort(parseISO(mission.end_at), {}, 'EEEE d LLL HH:mm')}</Text>
          </VStack>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack spacing={1}>
              <Text color='fg.subtle'><MdLocationCity /></Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                Structure organisatrice
              </Text>
            </HStack>
            <Text noOfLines={1}>{structure?.name}</Text>
          </VStack>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack spacing={1}>
              <Text color='fg.subtle'><TbMap /></Text>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>
                Adresse
              </Text>
            </HStack>
            <Text>{mission.address}</Text>
          </VStack>

          <Heading size={'2xs'}>Résumé</Heading>
          <VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
            <HStack flexWrap={'wrap'} alignContent={'flex-start'} justifyContent={'flex-start'}>
              <Text color="fg.muted">
                {mission.content || 'Aucune description'}
              </Text>
            </HStack>
          </VStack>


          <Heading size={'2xs'}>Inscriptions</Heading>

          {mission.mission_requirements.sort((a, b) => a.role.localeCompare(b.role)).map(mr => {

            return (<VStack flexWrap={'wrap'} alignItems={'flex-start'} alignContent={'flex-start'} justifyContent={'flex-start'}>
              <HStack spacing={1}>
                <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{(mr.total || 0) - (mr.missing || 0)}/{(mr.total || 0)} {mr.role}</Text>
              </HStack>

              <HStack spacing={1} flexWrap={'wrap'}>
                {[...Array(mr.validated > 0 ? mr.validated : 0)].map((elt, index) => (
                  <Avatar icon={<TbCheck fontSize='1.1rem' />} size={'xs'} bg={'green.600'} color={'white'} />
                ))}
                {[...Array(mr.pending > 0 ? mr.pending : 0)].map((elt, index) => (
                  <Avatar icon={<TbClock fontSize='1.1rem' />} size={'xs'} bg={'green.600'} color={'white'} />
                ))}
                {[...Array(mr.missing > 0 ? mr.missing : 0)].map((elt, index) => (
                  <Avatar icon={<TbQuestionMark fontSize='1.1rem' />} size={'xs'} bg={'gray.200'} color={'gray.800'} />
                ))}
              </HStack>

            </VStack>)
          })}


        </VStack>

      </Box>)}
    </Box>
  );
}

export default MissionDetail;
import { extendTheme } from '@chakra-ui/react'
import { theme as baseTheme } from './core'

import global from './global'
import colors from './colors'
import semanticTokens from './tokens'
import Button from './components/button'
import Link from './components/link'

import '@fontsource-variable/inter'
import '@fontsource/arvo/400.css';
import '@fontsource/arvo/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';

import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';



export const theme = extendTheme({
  ...baseTheme,
  colors,
  semanticTokens,
  styles: { global },
  config: {
    cssVarPrefix: 'compass',
    useSystemColorMode: false,
  },
  components: {
    ...baseTheme.components,
    Button,
    Link
  },
})

import { truncate } from "@/utils/string";
import { Badge, Box, Button, Checkbox, HStack, List, ListIcon, ListItem, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { TbArrowDown, TbArrowRight, TbMinus, TbPlus } from "react-icons/tb";
import {
  useHierarchicalMenu,
  UseHierarchicalMenuProps,
} from 'react-instantsearch';

export function isModifierClick(event: React.MouseEvent) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
    event.altKey ||
    event.ctrlKey ||
    event.metaKey ||
    event.shiftKey
  );
}

type HierarchicalTopListProps = Pick<
  ReturnType<typeof useHierarchicalMenu>,
  'items' | 'createURL'
> & {
  onNavigate(value: string): void
  level?: number
};

const colorForCategory = {
  'Urgence et Secourisme': 'red',
  'Action Sociale': 'blue',
  'Soutien aux activités': 'green',
  'Formation': 'pink',
  'Jeunesse': 'yellow',
}

function HierarchicalTopList({
  items,
  createURL,
  onNavigate,
  level = 0
}: HierarchicalTopListProps) {
  if (items.length === 0) {
    return null;
  }

  return (
    <VStack alignItems={'flex-start'} flexWrap={'wrap'} spacing={3}>

      <HStack alignItems={'flex-start'} flexWrap={'wrap'} spacing={3}>
      {items.map((item) => (
        <VStack alignItems={'flex-start'} key={item.value}>
          {level === 0 ? (<Button
            as={'a'}
            href={createURL(item.value)}
            variant={'ghost-on-accent'}
            colorScheme={colorForCategory[item.label] || 'grey'}
            onClick={(event) => {
              if (isModifierClick(event)) {
                return;
              }
              event.preventDefault();
              
              onNavigate(item.value);
            }}
            isActive={item.isRefined}
            // style={{ fontWeight: item.isRefined ? 'bold' : 'normal' }}
            >
            <HStack>
              <Text fontWeight={level == 0 ? '400' : '400'} noOfLines={1}>{item.label}</Text>
              <Badge size='sm'>{item.count}</Badge>
            </HStack>
          </Button>) : (<Button
            as={'a'}
            size={'sm'}
            variant={'link'}
            href={createURL(item.value)}
            onClick={(event) => {
              if (isModifierClick(event)) {
                return;
              }
              event.preventDefault();

              onNavigate(item.value);
            }}
            isActive={item.isRefined}
          // style={{ fontWeight: item.isRefined ? 'bold' : 'normal' }}
          >
            <HStack>
              <Text fontWeight={level == 0 ? '400' : '400'} noOfLines={1}>{truncate(item.label, 40)}</Text>
              <Badge size='sm'>{item.count}</Badge>
            </HStack>
          </Button>)}
        </VStack>
      ))}
      </HStack>
      {/* <HStack alignItems={'flex-start'} spacing={3} maxW={'100%'} overflowX={'auto'}>
        {items.map((item) => (
            item.data && (
              <HierarchicalTopList
                items={item.data}
                onNavigate={onNavigate}
                createURL={createURL}
                level={level + 1}
              />
            )
        ))}

      </HStack> */}
    </VStack>
  );
}

export function HierarchicalTopMenu(props: UseHierarchicalMenuProps) {
  const {
    items,
    refine,
    canToggleShowMore,
    toggleShowMore,
    isShowingMore,
    createURL,
  } = useHierarchicalMenu(props);

  return (
    <>
      <HierarchicalTopList
        items={items}
        onNavigate={refine}
        createURL={createURL}
      />
      {props.showMore && (
        <button disabled={!canToggleShowMore} onClick={toggleShowMore}>
          {isShowingMore ? 'Voir moins' : 'Voir plus'}
        </button>
      )}
    </>
  );
}
// import "instantsearch.css/themes/algolia-min.css";

import { Box, Text, VStack } from '@chakra-ui/react';
import MissionRefinementList from "@/screens/MissionRefinementList/MissionRefinementList";

import { HierarchicalMenu } from '@/components/HierarchicalMenu/HiercharchicalMenu';
import MissionStatusRefinementList from '@/screens/MissionRefinementList/MissionStatusRefinementList';
import { HiercharchicalMenuForSublevel } from '@/components/HierarchicalMenu/HiercharchicalMenuForSublevel';


export const MissionsListFilters = () => {

  return (
    <Box px={3} position={'sticky'} maxH={'100vh'} overflowY={'auto'} top={'10px'} minWidth={{ base: '100%', md: '300px' }} maxWidth={{ base: '100%', md: '300px' }} borderRightWidth={{ base: '0px', md: '1px' }} borderRightColor={'bg.muted'}>
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Catégorie</Text>
        <HiercharchicalMenuForSublevel
          attributes={[
            'categorie.lvl0',
            'categorie.lvl1',
          ]}
        />
      </VStack>
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Tags</Text>
        <MissionStatusRefinementList attribute="tags" />
      </VStack>
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Rôle manquant</Text>
        <MissionStatusRefinementList attribute="missing_requirements" />
      </VStack>

      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>UL</Text>
        <MissionRefinementList attribute="structure" />
      </VStack>
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Statut</Text>
        <MissionStatusRefinementList attribute="statut" />
      </VStack>

    </Box>
  );
}

// import "instantsearch.css/themes/algolia-min.css";

import { useParams } from 'react-router-dom';
import { HStack, VStack } from '@chakra-ui/react';

import {
  Breadcrumb,
  useHits} from "react-instantsearch";
import { ClearRefinements } from '@/components/ClearRefinements/ClearRefinements';
import { SortBy } from '@/components/SortBy/SortBy';
import { SearchBox } from '@/components/SearchBob/SearchBox';


export const MissionsListToolbar = () => {

  const { orgId } = useParams()
  const { hits } = useHits();

  return (
    <VStack spacing={0} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
      <HStack flexDirection={{ base: 'column', sm: 'row' }} p={3} borderBottomWidth={'1px'} borderBottomColor={'bg.muted'} spacing={6} alignContent={'flex-start'} alignItems={'center'} justifyContent={'space-between'}>
        

        <HStack>
          {/* <Text minW={'70px'} color={'fg.muted'} pb={1} fontSize='md'>Trier par</Text> */}
          <SortBy
            items={[
              { value: "Mission", label: "Défaut" },
              {
                value: "Mission:mission_type:asc",
                label: "type"
              },
              {
                value: "Mission:begin_at:asc",
                label: "Date"
              },
            ]}
            
          />
        </HStack>


        <HStack flex={1}>
          <SearchBox />
        </HStack>

        <HStack alignContent={'baseline'} justifyContent={'flex-end'}>
          <ClearRefinements />
        </HStack>

      </HStack>

    </VStack>
  );
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

// Sentry.init({
//   dsn: "https://b2c53de8b3a3584a76c779f9a374aaf4@o4507697422467072.ingest.de.sentry.io/4507697425743952",
//   integrations: [
//     // Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration(),
//     Sentry.reactRouterV6BrowserTracingIntegration({
//       useEffect: React.useEffect,
//       useLocation,
//       useNavigationType,
//       createRoutesFromChildren,
//       matchRoutes,
//     }),
//   ],
//   environment: import.meta.env.VITE_APP_ENV || 'dev',
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/api\.compass\.mashum\.org/, /^https:\/\/api\.compass\.mashum\.org/],
//   // Profiling
//   profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   enabled: (import.meta.env.VITE_APP_ENV || 'dev') !== 'dev',
// });



ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

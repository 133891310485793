
import { HStack, Heading, Image, useColorModeValue } from '@chakra-ui/react';
import LogoSvg from './compass.svg';
import LogoSvgDark from './compass.svg';

export const Logo = ({
  withIcon = true,
  withText = false,
  maxW = '2em',
}: {
  withIcon?: boolean,
  withText?: boolean,
  maxW?: string,
}) => {

  const Icon = useColorModeValue(LogoSvg, LogoSvgDark)
  return (<HStack>
    {/* {withIcon && <Image pl={0} maxW='4em' src={logoIconUrl} />} */}
    {withIcon && <Image pl={0} loading="lazy" alt="Logo" maxW={maxW} src={Icon} />}
    {withText && <Heading color={'brand.800'} size={"sm"}>Compass</Heading>}
  </HStack>)
}
// import "instantsearch.css/themes/algolia-min.css";

import { Box, Text, VStack } from '@chakra-ui/react';
import MissionRefinementList from "@/screens/MissionRefinementList/MissionRefinementList";

import { HierarchicalMenu } from '@/components/HierarchicalMenu/HiercharchicalMenu';
import MissionStatusRefinementList from '@/screens/MissionRefinementList/MissionStatusRefinementList';
import { HierarchicalTopMenu } from '@/components/HierarchicalMenu/HiercharchicalTopMenu';


export const CategoryNav = () => {

  return (
    <Box
      px={3}
      py={3}
      // position={'sticky'}
      // top={'0px'}
      minWidth={{ base: '100%', md: '100%' }}
      maxWidth={{ base: '100%', md: '100%' }}
      borderBottomWidth={{ base: '0px', md: '1px' }}
      borderBottomColor={'bg.muted'}
    >
      <HierarchicalTopMenu
          attributes={[
            'categorie.lvl0',
            'categorie.lvl1',
          ]}
        />
    </Box>
  );
}

import { ROUTES } from "../constants/routes";
import { RecordId } from "../interfaces/id";
import { CreateUserAttributes, ShortUserAttributes, User, UserAttributes, UserAttributesMeta } from "../interfaces/user";
import { ApiCollectionResponse, ApiResource, ApiResourceResponse, defaultAuthHeaders, defaultHeaders, handleApiResponse } from "../utils/api";
import { storage } from "../utils/storage";
import QueryString from "qs";

export const getUserInfoKey = "UserInfo";
export const getOrganizationUsersKey = "OrganizationUsers"
export const getUserKey = "User"

export interface AuthResponse {
  data: User
  jwt: string;
  relationships?: any
}

export interface LoginPayload {
  user: LoginPayloadValues
}

export interface LoginPayloadValues {
  email: string,
  password: string
}

export interface PasswordResetPayloadValues {
  reset_password_token: string,
  password: string
  password_confirmation: string
}

export interface PasswordResetPayload {
  user: PasswordResetPayloadValues
}

export interface SignupPayload {
  user: SignupPayloadValues
  redirectTo?: string
}


export interface SignupPayloadValues {
  email: string,
}

export interface AcceptInvitationPayloadValues {
  password: string,
  first_name: string,
  last_name: string,
}

export async function handleAuthApiResponse(response: Response): Promise<AuthResponse> {
  const data = await response.json();

  const jwt = response.headers.get("Authorization") || ''
  if (response.ok) {
    // console.log("handleAuthApiResponse OK", { data, jwt })
    return { ...data, jwt };
  } else {
    return Promise.reject(data);
  }
}

export async function handleSearchTokenResponse(response: Response): Promise<string> {
  const data = await response.json();

  if (response.ok) {
    return data.token
  } else {
    return Promise.reject(data);
  }
}

export async function getUserProfile(): Promise<AuthResponse> {
  return await fetch(ROUTES.auth.me, {
    headers: {
      Authorization: storage.getToken(),
      ...defaultHeaders,
    },
  }).then(handleAuthApiResponse);
}

export async function getUserSearchToken(): Promise<string> {
  return await fetch(ROUTES.auth.search_token, {
    headers: {
      Authorization: storage.getToken(),
      ...defaultHeaders,
    },
  }).then(handleSearchTokenResponse);
}


export async function getUserInfo(userId: RecordId): Promise<AuthResponse> {
  return await fetch(ROUTES.users.get(userId), {
    headers: {
      Authorization: storage.getToken(),
      ...defaultHeaders,
    },
  }).then(handleAuthApiResponse);
}

export async function getInvitation(invitationToken: string): Promise<AuthResponse> {
  return await fetch(ROUTES.users.getInvitation(invitationToken), {
    headers: {
      ...defaultHeaders,
    },
  }).then(handleAuthApiResponse);
}

export async function acceptInvitation(invitationToken: string, data: AcceptInvitationPayloadValues): Promise<AuthResponse> {
  return await fetch(ROUTES.users.getInvitation(invitationToken), {
      method: 'PATCH',
      body: JSON.stringify({ accept_invitation: {...data, invitation_token: invitationToken }}),
      headers: defaultHeaders,
    })
    .then(handleAuthApiResponse);
}


export async function loginWithEmailAndPassword(data: LoginPayload): Promise<AuthResponse> {
  return await fetch(ROUTES.auth.login, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: defaultHeaders,
    })
    .then(handleAuthApiResponse);
}

export async function resetWithEmail(
  data: SignupPayload
): Promise<AuthResponse> {
  return await fetch(ROUTES.auth.createFromEmail, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: defaultHeaders,
    })
    .then(handleAuthApiResponse);
}

export async function resetPassword(
  data: PasswordResetPayload
): Promise<Response> {
  return window
    .fetch(ROUTES.auth.password, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: defaultHeaders,
    })
}


export async function sendMagicLink(
  data: SignupPayload
): Promise<AuthResponse> {
  return await fetch(ROUTES.auth.sendMagicLink, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: defaultHeaders,
  })
    .then(handleAuthApiResponse);
}

export async function loginWithMagicToken(token: string): Promise<AuthResponse> {
  return await fetch(ROUTES.auth.loginWithMagicLink(token), {
    method: 'GET',
    headers: defaultHeaders,
  })
    .then(handleAuthApiResponse);
}


export async function updateUser(userId: RecordId, user: Partial<UserAttributes>): Promise<ApiResourceResponse<UserAttributes>> {
  const response = await fetch(
    ROUTES.users.update(userId), {
      headers: defaultAuthHeaders(),
      method: 'PUT',
      body: JSON.stringify({ user }),
    }
  )
  return await handleApiResponse(response);
}

export async function getOrganizationUsers(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<UserAttributes, unknown, UserAttributesMeta>> {
  const params = QueryString.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.users.list(organizationId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getStructureUsers(structureId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<UserAttributes, unknown, UserAttributesMeta>> {
  const params = QueryString.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.users.listForStructure(structureId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getUser(userId: RecordId): Promise<ApiResourceResponse<UserAttributes, unknown, UserAttributesMeta>> {
  const response = await fetch(
    ROUTES.users.get(userId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getUserMissionIds(): Promise<{[key: string]: {role: string, statut: string }}> {
  const response = await fetch(
    ROUTES.users.mission_ids(), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function createFromEmail(
  data: CreateUserAttributes
): Promise<ApiResourceResponse<ShortUserAttributes, unknown, unknown>> {
  const response = await fetch(ROUTES.users.create_from_email(), {
    method: 'POST',
    body: JSON.stringify(data),
    headers: defaultAuthHeaders(),
  })
  return await handleApiResponse(response);
}

export function getUserQuery(userId: RecordId) {
  return {
    queryKey: [getUserKey, userId],
    queryFn: () => getUser(userId)
  }
}


export const capitalize = (string: string) => string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';

export const truncate = (str, num) => {
  if (!str) {
    return ""
  } else if (str.length <= num) {
    return str
  } else {
    return str.slice(0, num) + '...'
  }
}

export const cx = (...args: unknown[]) => {
  return args
    .flat()
    .filter(x => typeof x === 'string')
    .join(' ')
    .trim()
}

export const backgroundForString = (stringInput: string): string => {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 75%, 55%)`;
}

export const stringToColor = (string, saturation = 65, lightness = 55) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
}

export const poppinsFont = '"Poppins", "Lato", -apple-system, system-ui, sans-serif'

import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

import { EmptyLayout } from "./components/Layout/EmptyLayout";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Auth } from "./screens/Auth/Auth";
import { NotFound } from "./components/NotFound/NotFound";
import { getUserProfile, getUserSearchToken } from "./api/user";
import { QueryClient } from "@tanstack/react-query";
import { ResetPassword } from "./screens/Auth/ResetPassword";
import { LOCALSTORAGE_REDIRECT_KEY } from "./constants/routes";
import { getPathFromRequest } from "./utils/route";
import { Magic } from "./screens/Auth/Magic";

import AllMissionsPage from "./pages/Missions/AllMissionsPage"
import { Layout } from "./components/Layout/Layout";

const userRedirectLoader = async ({ request }: { request: Request }) => {

  try {
    const { data } = await getUserProfile();
    if (!data) {
      // console.log(`Stored ${getPathFromRequest(request)} for redirect`)
      localStorage.setItem(LOCALSTORAGE_REDIRECT_KEY, getPathFromRequest(request))
      return redirect("/login");
    }
    // Sentry.setUser({ email: data?.attributes?.email, id: data?.attributes?.id });

  } catch (error) {
    // console.log(`Stored ${getPathFromRequest(request)} for redirect`)
    localStorage.setItem(LOCALSTORAGE_REDIRECT_KEY, getPathFromRequest(request))
    console.error(error)
    return redirect("/login");
  }
  return null
};


const userSearchLoader = async ({ request }: { request: Request }) => {
  return await getUserSearchToken();
};



// const sentryCreateBrowserRouter =
//   Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = (queryClient: QueryClient) => createBrowserRouter(
    createRoutesFromElements(
      <Route element={<EmptyLayout />}>

        <Route
          element={<Layout />}
          errorElement={<ErrorBoundary />}
          loader={userRedirectLoader}
        >
          <Route path="missions" errorElement={<ErrorBoundary />}>
            <Route loader={userSearchLoader} index element={<AllMissionsPage />} />
          </Route>
        </Route>

        <Route path="/login" element={<Auth />} />
        <Route path="/password/edit" element={<ResetPassword />} />
        <Route path="/magic/:token" element={<Magic />} />

        <Route index element={<Navigate to={'missions'} />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    )
)